import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import Link from 'components/atoms/Link';
import TextFormatter from 'components/atoms/TextFormatter';

const alternativeWrapperCss = css`
  background-color: ${({ theme }) => theme.colors.neutral5};
  padding: 1em 0.7em;
  margin-bottom: 0.5em;
`;

const noAlternativeWrapperCss = css`
  border: 0.0625em solid ${({ theme }) => theme.colors.neutral4};
  padding: 0.5em;
  margin-bottom: 1em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    padding: 1em;
  }
`;

const Wrapper = styled.div`
  border-radius: 0.25em;

  ${({ alternative }) => (alternative ? alternativeWrapperCss : noAlternativeWrapperCss)};

  p:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled(Link)`
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  text-decoration: none;
  display: block;
`;

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.neutral3};
  margin: 0 0 0.5rem 0;
  display: block;
`;

const NewsCard = ({ news, alternative, className }) => (
  <Wrapper alternative={alternative} className={className}>
    <Subtitle>{dayjs(news.createdAt).format('DD MMM YYYY')}</Subtitle>
    <Title to={`/news/${news.id}`}>{news.name}</Title>
    <TextFormatter text={news.preview} />
  </Wrapper>
);

export default NewsCard;
